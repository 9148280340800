import React from 'react'
import "./Consent.scss";
import CookieConsent from "react-cookie-consent";
import Variables from "../../variables.module.scss"

type ConsentProps = {
    t:Function
}

export default function Consent(props:ConsentProps) {
    return (
    <CookieConsent
        location="none"
        buttonText={props.t('cookies.accept')}
        style={{ 
          display: "block",
          position: "fixed",
          maxWidth: "400px",
          width: "80%",
          bottom: "4%",
          left: "auto",
          right: "10%",
          textAlign: "left",
          background: Variables["redlab-red"],
          boxShadow: `0px 0px 1px ${Variables["redlab-grey"]}`
        }}
        buttonStyle={{
            width:"80%",
            maxWidth:"140px",
            background: Variables["redlab-white"],
            boxShadow: `0px 0px 1px ${Variables["redlab-grey"]}`,
            color: Variables["redlab-grey"],
            fontSize: "13px"
        }}
        contentStyle={{flex: 1}}
      >
        {props.t('cookies.msg')}
      </CookieConsent>
    )
}
