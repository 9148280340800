import React, { useEffect, useState } from "react";
import "./AxisHelper.scss";
import { AxesHelper, Material } from "three";

const axes:AxesHelper = new AxesHelper(4);
(axes.material as Material).depthTest = false;
axes.renderOrder = 1;
axes.layers.set(3);

export default function AxisHelper() {
    return (
        <primitive 
            key="primitive_axes"
            object={axes}
            position={[0.0, 0.0, 0.0]}
        />
    );
}
