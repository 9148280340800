import { routePath } from "../Constants";

enum Routes {
  NONE,
  TOP,
  HOME,
  ABOUT,
  SERVICES,
  SERVICES_PAGE,
  INFO,
  CONTACTS,
  EMAIL
}

export function getRouteName(aRoute: Routes): string {
  var theRoute = "";
  switch (aRoute) {
    case Routes.EMAIL:
      theRoute = "email.php";
      break;
    case Routes.CONTACTS:
      theRoute = "contacts";
      break;
    case Routes.INFO:
      theRoute = "info";
      break;
    case Routes.SERVICES_PAGE:
      theRoute = "services";
      break;
    case Routes.SERVICES:
      theRoute = "ser";
      break;
    case Routes.ABOUT:
      theRoute = "about";
      break;
    case Routes.TOP:
      theRoute = "top";
      break;
    case Routes.HOME:
    default:
      theRoute = "";
      break;
  }

  return theRoute;
}

export function getRouteLocation(aRoute: Routes): string {
  return `${routePath}${getRouteName(aRoute)}`;
}

export function getRouteLink(aRoute: Routes): string {
  return `${getRouteName(aRoute)}`;
}

export function getRouteSection(aRoute: Routes): string {
  return `#${getRouteName(aRoute)}`;
}

export default Routes;
