import React, { Fragment, useEffect } from "react";
import "./RedScroll.scss";
import { useHistory } from "react-router-dom";

type RedScrollProps = {
  children?: React.ReactChild | React.ReactChild[];
};

export default function RedScroll(props: RedScrollProps) {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return <>{props.children}</>;
}
