import React, { useEffect } from "react";
import "./HomeFallback.scss";
import ReactGA from "react-ga";
import RedImage from "../Common/RedImage/RedImage";
import RedVideo from "../Common/RedVideo/RedVideo";
import { MTiltText } from "../Common/TiltText/TiltText";
import HomeOverlayParticles from "./HomeOverlayParticles/HomeOverlayParticles";
import { imagesPath } from '../../Constants';

type HomeFallbackProps = {
  t: Function;
};

export default function HomeFallback(props: HomeFallbackProps) {

  useEffect(() => {
    ReactGA.pageview('/redlab/homefallback');
  }, []);

  return (
    <div className="HomeFallback">
      <div className="HomeFallbackOverlay">
        <div className="HomeFallbackLogo HomeFallbackFadeIn">
          <img src={`${imagesPath}logo.svg`} alt="RedLab" />
        </div>
        <div className="HomeFallbackText HomeFallbackFadeIn">
          <div className="HomeFallbackTextContent">
            <MTiltText
              text={[
                {
                  key: "home.text1",
                  text: props.t("home.text1"),
                  initPosY: -window.innerHeight
                },
                {
                  key: "home.text2",
                  text: props.t("home.text2"),
                  initPosY: -window.innerHeight
                }
              ]}
              subtext={[
                {
                  key: "home.text3",
                  text: props.t("home.text3"),
                  initPosY: -window.innerHeight
                }
              ]}
            />
          </div>
        </div>
        <div className="HomeFallbackOverlayObjects HomeFallbackFadeIn">
          <HomeOverlayParticles />
        </div>
      </div>
      <div className="HomeFallbackContent">
        <RedVideo
          controls={false}
          src="Services.mp4"
          fallbackImage="default_background.jpg"
          isPlaying={true}
        />
      </div>
    </div>
  );
}
