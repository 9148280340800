import React from "react";
import "./ServicesPageInfo.scss";
import ReactHtmlParser from "react-html-parser";

type ServicesPageInfoProps = {
  text: string;
  outputs?: string;
};

export default function ServicesPageInfo(props: ServicesPageInfoProps) {
  return (
    <div className="ServicesPageInfo">
      <div className="ServicesPageInfoText">{ReactHtmlParser(props.text)}</div>
      {props.outputs ? (
        <div className="ServicesPageInfoOutputs">
          {ReactHtmlParser(props.outputs)}
        </div>
      ) : null}
    </div>
  );
}
