import React, { useEffect, useState } from "react";
import "./InputControl.scss";

type InputControlProps = {
  domElement: HTMLDivElement | null;
  isActive?: boolean;
  isVisible?: boolean;
  onScrollReset?: Function;
  onDragChange?: Function;
  onDragMoveX?: Function;
  onDragMoveY?: Function;
  onMove?: Function;
  onScrollY?: Function;
  onWheelY?: Function;
};

const moveXThreshold: number = 0.05;
const moveYThreshold: number = 0.1;
var isInputActive:boolean | undefined = false;

export default function InputControl(props: InputControlProps) {
  const [domElement, setDomElement] = useState<HTMLDivElement | null>(props.domElement);
  const [isRegistered, setIsRegistered] = useState(false);
  const [shouldRegister, setShouldRegister] = useState(false);
  var dragState: boolean = false;
  var dragX: number = 0.0;
  var dragY: number = 0.0;
  var lastDragX: number = 0.0;
  var lastDragY: number = 0.0;
  var initialDragX: number = 0.0;
  var initialDragY: number = 0.0;
  var lastScrollY: number = -1.0;

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
      deregister();
    };
  }, []);

  useEffect(() => {
    if(shouldRegister && !isRegistered) {
      register();
    } else if(!shouldRegister && isRegistered) {
      deregister();
    }
  }, [domElement]);

  useEffect(() => {
    setDomElement(props.domElement);
  }, [props.domElement]);

  useEffect(() => {
    isInputActive = props.isActive;
  }, [props.isActive]);

  useEffect(() => {
    if (props.isVisible) {
      register();
    } else {
      deregister();
    }
  }, [props.isVisible]);

  function register() {
    if (domElement) {
      domElement.onpointerdown = onPointerDown;
      domElement.onpointerup = onPointerCancel;
      domElement.onpointermove = onPointerMove;
      domElement.onpointercancel = onPointerCancel;
      domElement.onpointerleave = onPointerCancel;
      domElement.ontouchmove = onTouchMove;
      domElement.ontouchstart = onTouchStart;
      domElement.ontouchend = onTouchEnd;
      domElement.onwheel = onMouseWheel;
    }
    setShouldRegister(true);
    setIsRegistered(domElement != null);
  }

  function deregister() {
    if (domElement) {
      domElement.onpointerdown = null;
      domElement.onpointerup = null;
      domElement.onpointermove = null;
      domElement.onpointercancel = null;
      domElement.onpointerleave = null;
      domElement.ontouchmove = null;
      domElement.ontouchstart = null;
      domElement.ontouchend = null;
      domElement.onwheel = null;
    }
    setShouldRegister(false);
    setIsRegistered(false);
  }

  function onScroll(event: Event) {
    const delta = lastScrollY === -1 ? 0.0 : window.scrollY - lastScrollY;
    if (lastScrollY === -1) {
      lastScrollY = window.scrollY;
    }
    if (Math.abs(delta) > moveYThreshold && props.onScrollY) {
      lastScrollY = window.scrollY;
      props.onScrollY(delta);
    }
    if (window.scrollY === 0 && props.onScrollReset) {
      props.onScrollReset(true);
    }
  }

  function onMouseWheel(event: WheelEvent) {
    if (isInputActive && event.cancelable) {
      event.preventDefault();
    }
    if (props.onWheelY) {
      props.onWheelY(Math.random() * Math.sign(event.deltaY));
    }
  }

  function onPointerDown(event: PointerEvent) {
    if (isInputActive && event.cancelable) {
      event.preventDefault();
    }
    switch (event.pointerType) {
      case "mouse":
      case "pen":
        onMouseDown(event);
        break;
      default:
        break;
    }
  }

  function onPointerCancel(event: PointerEvent) {
    if (isInputActive && event.cancelable) {
      event.preventDefault();
    }
    switch (event.pointerType) {
      case "mouse":
      case "pen":
        onMouseCancel(event);
        break;
      default:
        break;
    }
  }

  function onPointerMove(event: PointerEvent) {
    if (isInputActive && event.cancelable) {
      event.preventDefault();
    }
    switch (event.pointerType) {
      case "mouse":
      case "pen":
        onMouseMove(event);
        break;
      default:
        break;
    }
  }

  function onMouseDown(event: PointerEvent) {
    var rect: DOMRect = domElement
      ? domElement.getBoundingClientRect()
      : window.document.documentElement.getBoundingClientRect();
    dragX = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    dragY = -((event.clientY - rect.top) / rect.height) * 2 + 1;
    onDrag(true, dragX, dragY);
  }

  function onMouseCancel(event: PointerEvent) {
    onDrag(false);
  }

  function onMouseMove(event: PointerEvent) {
    var rect: DOMRect = domElement
      ? domElement.getBoundingClientRect()
      : window.document.documentElement.getBoundingClientRect();
    dragX = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    dragY = -((event.clientY - rect.top) / rect.height) * 2 + 1;

    if (!dragState) {
      onMove(dragX, dragY, event.clientX, event.clientY);
    } else {
      onDragMove(dragX, dragY);
    }
  }

  function onMove(
    aDragX: number,
    aDragY: number,
    aPageX: number,
    aPageY: number
  ) {
    const theDragDiffX = aDragX - lastDragX;
    const theDragDiffY = aDragY - lastDragY;
    var isUpdated = false;
    if (Math.abs(theDragDiffX) > moveXThreshold) {
      lastDragX = aDragX;
      isUpdated = true;
    }
    if (Math.abs(theDragDiffY) > moveYThreshold) {
      lastDragY = aDragY;
      // We are only interested on the x movement for now
      // isUpdated = true;
    }
    if (isUpdated && props.onMove) {
      props.onMove(
        initialDragX - aDragX,
        initialDragY - aDragY,
        aPageX,
        aPageY
      );
    }
  }

  function onTouchStart(event: TouchEvent) {
    var touchEvent: Touch = event.changedTouches[0];
    var rect: DOMRect = domElement
      ? domElement.getBoundingClientRect()
      : window.document.documentElement.getBoundingClientRect();
    dragX = ((touchEvent.clientX - rect.left) / rect.width) * 2 - 1;
    dragY = -((touchEvent.clientY - rect.top) / rect.height) * 2 + 1;
    onDrag(true, dragX, dragY);
  }

  function onTouchEnd(event: TouchEvent) {
    onDrag(false);
  }

  function onTouchMove(event: TouchEvent) {
    if (isInputActive && event.cancelable) {
      event.preventDefault();
    }
    if (dragState) {
      var touchEvent: Touch = event.changedTouches[0];
      var rect: DOMRect = domElement
        ? domElement.getBoundingClientRect()
        : window.document.documentElement.getBoundingClientRect();
      dragX = ((touchEvent.clientX - rect.left) / rect.width) * 2 - 1;
      dragY = -((touchEvent.clientY - rect.top) / rect.height) * 2 + 1;
      onDragMove(dragX, dragY);
    }
  }

  function onDrag(aState: boolean, aDragX: number = 0.0, aDragY: number = 0.0) {
    if (aState === true) {
      lastDragX = aDragX;
      lastDragY = aDragY;
      initialDragX = aDragX;
      initialDragY = aDragY;
    } else {
      lastDragX = 0.0;
      lastDragY = 0.0;
      initialDragX = 0.0;
      initialDragY = 0.0;
    }
    dragState = aState;
    if (props.onDragMoveX) {
      props.onDragMoveX(0.0);
    }
    if (props.onDragMoveY) {
      props.onDragMoveY(0.0);
    }
    if (props.onDragChange) {
      props.onDragChange(aState);
    }
  }

  function onDragMove(aDragX: number, aDragY: number) {
    const theDragDiffX = aDragX - lastDragX;
    const theDragDiffY = aDragY - lastDragY;
    if (Math.abs(theDragDiffX) > moveXThreshold) {
      lastDragX = aDragX;
      if (props.onDragMoveX) {
        props.onDragMoveX(initialDragX - aDragX);
      }
    } else if (Math.abs(theDragDiffY) > moveYThreshold) {
      lastDragY = aDragY;
      if (props.onDragMoveY) {
        props.onDragMoveY(initialDragY - aDragY);
      }
    }
  }

  return null;
}
