import React, { CSSProperties, useEffect, useState } from "react";
import "./Services.scss";
import { Link } from "react-router-dom";
import { ServicesVO } from "../../types/ServicesVO";
import ServicesData from "../../data/content/services.json";
import ServicesListItem from "./ServicesListItem/ServicesListItem";
import ServicesItemImage from "./ServicesImage/ServicesImage";
import { imagesPath } from "../../Constants";
import Routes, { getRouteLink } from "../../types/Routes";

const data: ServicesVO[] = ServicesData;

type ServicesProps = {
  t: Function;
  style?: CSSProperties;
};

const speedServices = 2000;

export default function Services(props: ServicesProps) {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [isHovering, setIsHovering] = useState<boolean>(false);

  useEffect(() => {
    data.forEach((service:ServicesVO) => {
        new Image().src = imagesPath + service.icon;
    });
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isHovering) {
        var newIndex = 0;
        if (selectedIndex + 1 < data.length) {
          newIndex = selectedIndex + 1;
        }
        setSelectedIndex(newIndex);
      }
    }, speedServices);
    return () => {
      clearInterval(intervalId);
    };
  }, [selectedIndex, isHovering]);

  function onItemSelectingHandler(aItem: ServicesVO) {
    setSelectedIndex(aItem.index);
    setIsHovering(true);
  }

  function onItemSelectedHandler(aItem: ServicesVO) {
    setIsHovering(false);
  }

  return (
    <div className="Services" style={props.style}>
      <div className="ServicesBackground">
        <ServicesItemImage
          key="ServicesItemImage"
          item={data[selectedIndex]}
          title={props.t(data[selectedIndex].title)}
        />
      </div>
      <div className="ServicesContent">
        <div className="ServicesBody">
          <div className="ServicesRows">
            <div className="ServicesRow1">
              <div className="ServicesListContent">
                {data && data.length > 0
                  ? data.map(
                      (item: ServicesVO, index: number, arr: ServicesVO[]) => {
                        return (
                          <ServicesListItem
                            key={`ServicesListItem_${item.index}`}
                            item={item}
                            title={props.t(item.title)}
                            isSelected={selectedIndex === index}
                            onItemSelecting={onItemSelectingHandler}
                            onItemSelected={onItemSelectedHandler}
                          />
                        );
                      }
                    )
                  : null}
              </div>
              <div className="ServicesHorizontalSpacer" />
              <div className="ServicesInfoContent">
                <div className="ServicesInfoTitle">
                  <div>{props.t("services.infoTitle")}</div>
                </div>
                <div className="ServicesInfoText">
                  <div>{props.t("services.infoText")}</div>
                </div>
                <div className="ServicesInfoButton">
                  <Link to={getRouteLink(Routes.SERVICES_PAGE)}>
                    <div className="ServicesInfoButtonContent">
                      <div>{props.t("services.infoButton")}</div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="ServicesRow2" />
          </div>
        </div>
      </div>
    </div>
  );
}
