import React, { useEffect } from "react";
import './CameraControl.scss';
import { useThree } from "react-three-fiber";
import { Vector2 } from "three";
import { CameraParams } from "../../../types/ToolsVO";

type CameraControlProps = {
  params:CameraParams,
  windowSize:Vector2
}

export default function CameraControl(props:CameraControlProps) {
  const { camera, gl } = useThree();

  useEffect(() => {
    gl.setSize(props.windowSize.x, props.windowSize.y);
  }, [props.windowSize]);

  useEffect(() => {
    camera.lookAt(props.params.lookAtX, props.params.lookAtY, props.params.lookAtZ);
  }, [props.params]);

  return null;
}
