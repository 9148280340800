import React from "react";
import "./Lights.scss";
import RedSpotLight from "./RedSpotLight/RedSpotLight";
import RedPointLight from "./RedPointLight/RedPointLight";
import {
  AmbientLightParams,
  PointLightParams,
  ShadowsParams,
  SpotLightParams
} from "../../../types/ToolsVO";

export enum LightType {
  AMBIENT,
  POINT,
  SPOT
}

type LightsParams = {
  ambientLight: AmbientLightParams;
  spotlights: SpotLightParams[];
  pointlights: PointLightParams[];
  shadows: ShadowsParams;
};

export default function Lights(props: LightsParams) {
  return (
    <>
      {props.ambientLight.enable === false ? null : (
        <ambientLight
          intensity={props.ambientLight.intensity}
          color={props.ambientLight.color}
        />
      )}
      {props.spotlights.map((spotlight) =>
        !spotlight.enable ? null : (
          <RedSpotLight
            key={`RedSpotLight_${spotlight.id}`}
            light={spotlight}
            shadows={props.shadows}
          />
        )
      )}
      {props.pointlights.map((pointlight) =>
        !pointlight.enable ? null : (
          <RedPointLight
            key={`RedPointLight_${pointlight.id}`}
            light={pointlight}
          />
        )
      )}
    </>
  );
}
