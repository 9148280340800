import React, { useState } from "react";
import "./ServicesPageItems.scss";
import { ServicesVO } from "../../../types/ServicesVO";
import ServicesPageInfo from "../ServicesPageInfo/ServicesPageInfo";
import ServicesData from "../../../data/content/services.json";

const data: ServicesVO[] = ServicesData;

type ServicesPageItemsProps = {
  t: Function;
};

export default function ServicesPageItems(props: ServicesPageItemsProps) {
  return (
    <div className="ServicesPageItems">
      <div className="ServicesPageItemsList">
        <div className="ServicesPageItemsContent ServicesPageItemsServiceHeader">
          <div className="ServicesPageItemsContentBody">
            <div className="ServicesPageItemsContentTitle">
              <div className="ServicesPageItemsContentTitleBody">
                {props.t("servicesPage.titleServices")}
              </div>
            </div>
            <div className="ServicesPageItemsContentInfo">
              <ServicesPageInfo text={props.t("servicesPage.textServices")} />
            </div>
          </div>
        </div>
        {data && data.length > 0
          ? data.map((item: ServicesVO, index: number, arr: ServicesVO[]) => {
              return (
                <div
                  id={item.id}
                  key={`ServicesPageItem_${item.id}`}
                  className="ServicesPageItemsContent ServicesPageItemsService"
                >
                  <div className="ServicesPageItemsContentBody">
                    <div className="ServicesPageItemsContentTitle">
                      <div className="ServicesPageItemsContentTitleBody">
                        {props.t(item.title)}
                      </div>
                    </div>
                    <div className="ServicesPageItemsContentInfo">
                      <ServicesPageInfo
                        text={props.t(item.text)}
                        outputs={props.t(item.outputs)}
                      />
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
}
