import React, { useEffect, useRef } from "react";
import "./RedSpotLight.scss";
import { SpotLight } from "three";
import { ShadowsParams, SpotLightParams } from "../../../../types/ToolsVO";

type RedSpotLightParams = {
    light:SpotLightParams,
    shadows:ShadowsParams
}

export default function RedSpotLight(props:RedSpotLightParams) {
    const spot = useRef<SpotLight>();

    useEffect(() => {
        spot.current?.layers.set(0);
        spot.current?.layers.enable(1);
        spot.current?.layers.enable(2);
    }, [spot.current])

    return (<>
        { !props.light.enable ? null :
            <spotLight 
                ref={spot} 
                intensity={props.light.intensity}
                position={[props.light.positionX, props.light.positionY, props.light.positionZ]}
                angle={props.light.angle}
                color={props.light.color}
                distance={props.light.distance}
                decay={props.light.decay}
                penumbra={props.light.penumbra}
                castShadow={props.light.enable}
                shadow-autoUpdate={props.shadows.autoUpdate}
                shadow-bias={props.shadows.bias}
                shadow-normalBias={props.shadows.normalBias}
                shadow-mapSize-width={props.shadows.mapSizeWidth}
                shadow-mapSize-height={props.shadows.mapSizeHeight}
                shadow-focus={props.shadows.focus}
                shadow-radius={props.shadows.radius}
                shadow-camera-near={props.shadows.cameraNear}
                shadow-camera-far={props.shadows.cameraFar}
            />
        }
        { !spot?.current?.target ? null :
            <primitive 
                object={spot.current.target}
                position={[props.light.lookAtX, props.light.lookAtY, props.light.lookAtZ]}
            />
        }
    </>)
}
