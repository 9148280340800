import React from 'react';
import './ServicesImage.scss';
import CrossfadeImage from 'react-crossfade-image';
import { ServicesVO } from '../../../types/ServicesVO';
import { imagesPath } from "../../../Constants";

type ServicesImageProps = {
  item: ServicesVO,
  title:string
}

export default function ServicesImage(props:ServicesImageProps) {
  return (
    <CrossfadeImage
      className="ServicesImage"
      src={imagesPath + props.item.icon}
      alt={props.title}
      duration={300}
      style={{
        width: "100%",
        height: "100%",
        objectFit: "cover"
      }}
    />
  )
}
