import React from 'react';
import './BustoEffects.scss';
import { useFrame, useThree } from 'react-three-fiber';

export default function BustoEffects() {
    const { gl, scene, camera } = useThree();

    useFrame(renderCallback, 100);

    function renderCallback() {
      camera.layers.set(0);
      camera.layers.enable(1);
      camera.layers.enable(2);
      gl.render(scene, camera);
    }

    return null;
}
