import React, { useEffect, useState } from 'react'
import './BustoTools.scss';
import DatGui, { DatBoolean, DatButton, DatColor, DatFolder, DatNumber, DatString } from '@tim-soft/react-dat-gui';
import Ajv, { JSONSchemaType } from 'ajv';
import { BustoToolsParams } from '../../../types/BustoToolsVO';
import defaultParamsBusto from "../../../data/params/defaultParamsBusto";

const ajv = new Ajv();
const schema: JSONSchemaType<BustoToolsParams> = {
    type: "object",
    required: ['enableTools'],
    additionalProperties: true
}
const validate = ajv.compile(schema);

type BustoToolsProps = {
    params: BustoToolsParams,
    updateParams:Function
}

export default function BustoTools(props:BustoToolsProps) {

    const [guiData, setGuiData] = useState<BustoToolsParams>(props.params);
    const versionLabel = `Tools v${props.params.version}`;

    useEffect(() => {
        props.updateParams({
            ... guiData
        });
    }, [guiData])

    const handleFileUpload = (e:any) => {
        const { files } = e.target;
        if (files && files.length) {
            const reader = new FileReader()
            reader.onload = async (e) => { 
                var content = reader.result?.toString();
                if(content) {
                    try {
                        var newData:BustoToolsParams = JSON.parse(content);
                        if(newData) {
                            if (validate(newData)) {
                                console.log(newData);
                                setGuiData(newData);
                            } else {
                                console.log(validate.errors);
                            }
                        }
                    } catch (error) {
                        console.log(error);
                    }
                    
                }
            };
            reader.readAsText(e.target.files[0])
        }
    };

    function onResetHandler() {
        setGuiData(defaultParamsBusto);
    }

    function onLoadClickHandler() {
        const element = document.createElement("input");
        element.type = "file";
        element.onchange = handleFileUpload;
        document.body.appendChild(element);
        element.click();
    }

    function onSaveClickHandler() {
        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(guiData)], {type: 'text/json'});
        element.href = URL.createObjectURL(file);
        element.download = "redlabConfig.json";
        document.body.appendChild(element);
        element.click();
    }

    return (
        <div className="Tools">
            <DatGui data={guiData} onUpdate={setGuiData} className="datGUI">
                <DatFolder title={versionLabel} closed={true}>
                    <DatFolder title="Buttons" closed={true}>
                        <DatButton label="Save" onClick={onSaveClickHandler}/>
                        <DatButton label="Load" onClick={onLoadClickHandler}/>
                        <DatButton label="Reset" onClick={onResetHandler}/>
                    </DatFolder>
                    <DatFolder title="Helpers" closed={true}>
                        <DatBoolean path='helpers.enableAxisHelper' label="Axis"/>
                        <DatBoolean path='helpers.enableLightHelpers' label="All light helpers"/>
                    </DatFolder>
                    <DatFolder title="Scene" closed={true}>
                        <DatBoolean path='scene.invalidateFrameloop' label='Invalidate Frameloop'/>
                        <DatNumber path='scene.targetFPS' label="Target FPS" min={0} max={90} step={10}/>
                        <DatBoolean path='scene.autoRotateObjects' label='Auto Rotate Objects'/>
                        <DatNumber path='scene.objectRotationRate' label="Object rotation rate" min={0.0} max={0.1} step={0.001}/>
                    </DatFolder>
                    <DatFolder title="Camera" closed={true}>
                        <DatFolder title="Position" closed={true}>
                            <DatNumber path='cameraStart.positionX' label="x" min={-30.0} max={30.0} step={0.01}/>
                            <DatNumber path='cameraStart.positionY' label="y" min={-30.0} max={30.0} step={0.01}/>
                            <DatNumber path='cameraStart.positionZ' label="z" min={-30.0} max={30.0} step={0.01}/>
                        </DatFolder>
                        <DatFolder title="LookAt" closed={true}>
                            <DatNumber path='cameraStart.lookAtX' label="x" min={-30.0} max={30.0} step={0.01}/>
                            <DatNumber path='cameraStart.lookAtY' label="y" min={-30.0} max={30.0} step={0.01}/>
                            <DatNumber path='cameraStart.lookAtZ' label="z" min={-30.0} max={30.0} step={0.01}/>
                        </DatFolder>
                        <DatFolder title="Params (ReadOnly)" closed={true}>
                            <DatString path='cameraStart.fov' label='fov'/>
                            <DatString path='cameraStart.near' label='near'/>
                            <DatString path='cameraStart.far' label='far'/>
                            <DatString path='cameraStart.focus' label='focus'/>
                            <DatString path='cameraStart.filmGauge' label='filmGauge'/>
                            <DatString path='cameraStart.filmOffset' label='filmOffset'/>
                        </DatFolder>
                    </DatFolder>
                    <DatFolder title="Lights" closed={true}>
                        <DatFolder title="AmbientLight" closed={true}>
                            <DatBoolean path='ambientLight.enable' label='Enable'/>
                            <DatNumber path='ambientLight.intensity' label='Intensity' min={0.0} max={1.0} step={0.01}/>
                            <DatColor path='ambientLight.color' label="Color"/>
                        </DatFolder>
                        {props.params.pointlights.map((pointLight, index) => 
                            <DatFolder key={pointLight.id} title={`Pointlight ${pointLight.id}`} closed={true}>
                                <DatBoolean path={`pointlights[${index}].enable`} label="Enable"/>
                                <DatBoolean path={`pointlights[${index}].enableHelper`} label="Helper"/>
                                <DatFolder title="Position" closed={true}>
                                    <DatNumber path={`pointlights[${index}].positionX`} label="x" min={-30.0} max={30.0} step={0.1}/>
                                    <DatNumber path={`pointlights[${index}].positionY`} label="y" min={-30.0} max={30.0} step={0.1}/>
                                    <DatNumber path={`pointlights[${index}].positionZ`} label="z" min={-30.0} max={30.0} step={0.1}/>
                                </DatFolder>
                                <DatNumber path={`pointlights[${index}].intensity`} label="Intensity" min={0.0} max={15.0} step={0.1}/>
                                <DatColor path={`pointlights[${index}].color`} label="Color"/>
                                <DatNumber path={`pointlights[${index}].decay`} label="Decay" min={0.0} max={2.0} step={0.1}/>
                                <DatNumber path={`pointlights[${index}].distance`} label="Distance" min={0.0} max={50.0} step={1.0}/>
                                <DatBoolean path={`pointlights[${index}].castShadow`} label="Cast shadow"/>
                            </DatFolder>
                        )}
                        {props.params.spotlights.map((spotLight, index) => 
                            <DatFolder key={spotLight.id} title={`Spotlight ${spotLight.id}`} closed={true}>
                                <DatBoolean path={`spotlights[${index}].enable`} label="Enable"/>
                                <DatBoolean path={`spotlights[${index}].enableHelper`} label="Helper"/>
                                <DatFolder title="Position" closed={true}>
                                    <DatNumber path={`spotlights[${index}].positionX`} label="x" min={-30.0} max={30.0} step={0.1}/>
                                    <DatNumber path={`spotlights[${index}].positionY`} label="y" min={-30.0} max={30.0} step={0.1}/>
                                    <DatNumber path={`spotlights[${index}].positionZ`} label="z" min={-30.0} max={30.0} step={0.1}/>
                                </DatFolder>
                                <DatFolder title="LookAt" closed={true}>
                                    <DatNumber path={`spotlights[${index}].lookAtX`} label="x" min={-30.0} max={30.0} step={0.1}/>
                                    <DatNumber path={`spotlights[${index}].lookAtY`} label="y" min={-30.0} max={30.0} step={0.1}/>
                                    <DatNumber path={`spotlights[${index}].lookAtZ`} label="z" min={-30.0} max={30.0} step={0.1}/>
                                </DatFolder>
                                <DatNumber path={`spotlights[${index}].intensity`} label="Intensity" min={0.0} max={15.0} step={0.1}/>
                                <DatNumber path={`spotlights[${index}].angle`} label="Angle" min={0.0} max={Math.PI} step={0.01}/>
                                <DatColor path={`spotlights[${index}].color`} label="Color"/>
                                <DatNumber path={`spotlights[${index}].decay`} label="Decay" min={0.0} max={1.0} step={0.1}/>
                                <DatNumber path={`spotlights[${index}].penumbra`} label="Penumbra" min={0.0} max={1.0} step={0.1}/>
                                <DatNumber path={`spotlights[${index}].distance`} label="Distance" min={0.0} max={50.0} step={1.0}/>
                                <DatBoolean path={`spotlights[${index}].castShadow`} label="Cast shadow"/>
                            </DatFolder>
                        )}
                    </DatFolder>
                    <DatFolder title="Shadows" closed={true}>
                        <DatBoolean path='shadows.autoUpdate' label='Auto update'/>
                        <DatNumber path='shadows.bias' label='Bias' min={0.0} max={0.0005} step={0.0001}/>
                        <DatNumber path='shadows.normalBias' label='Normal Bias' min={0.0} max={1.0} step={0.1}/>
                        <DatNumber path='shadows.mapSizeWidth' label='MapSize Width' min={0} max={4096} step={512}/>
                        <DatNumber path='shadows.mapSizeHeight' label='MapSize Height' min={0} max={4096} step={512}/>
                        <DatNumber path='shadows.focus' label='Focus' min={0.0} max={1.0} step={0.1}/>
                        <DatNumber path='shadows.radius' label='Radius' min={0.0} max={10.0} step={1.0}/>
                        <DatNumber path='shadows.cameraNear' label='Camera near' min={0.0} max={50.0} step={1}/>
                        <DatNumber path='shadows.cameraFar' label='Camera far' min={0.0} max={1000.0} step={10}/>
                    </DatFolder>
                </DatFolder>
            </DatGui>
        </div>
    );
}
