import React from 'react'
import './FpsDisplay.scss';
import FPSStats from "react-fps-stats";

export default function FpsDisplay() {

  return <div className="FpsDisplay">
    <FPSStats />
  </div>;
}
