import React from 'react'
import './LightsHelpers.scss';
import LightHelper, { LightType } from "../LightHelper/LightHelper";
import { PointLightParams, SpotLightParams } from '../../../../types/ToolsVO';

type LightsHelpersParams = {
    spotlights:SpotLightParams[],
    pointlights:PointLightParams[]
}

export default function LightsHelpers(props:LightsHelpersParams) {
   
    return (<>
        { props.spotlights.map((spotlight) => 
            !spotlight.enable || !spotlight.enableHelper ? null :
            <LightHelper
                key={`SPOT_LightHelper_${spotlight.id}`}
                type={LightType.SPOT}
                id={spotlight.id}
                position={[spotlight.positionX, spotlight.positionY, spotlight.positionZ]}
                lookAt={[spotlight.lookAtX, spotlight.lookAtY, spotlight.lookAtZ]}
            />
        )}
        { props.pointlights.map((pointlight) => 
            !pointlight.enable || !pointlight.enableHelper ? null :
            <LightHelper
                key={`POINT_LightHelper_${pointlight.id}`}
                type={LightType.POINT}
                id={pointlight.id}
                position={[pointlight.positionX, pointlight.positionY, pointlight.positionZ]}
            />
        )}
    </>);
}
