import React, { useState } from 'react';
import './HomeWheel.scss';
import Lottie from 'react-lottie';
import mouseWheel from '../../../data/animations/mouse_wheel.json'

type HomeWheelProps = {
}

export default function HomeWheel() {
    const [animationState, setAnimationState] = useState({
        isStopped: false,
        isPaused: false
    });

    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: mouseWheel,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className="HomeWheel">
            <Lottie
                width={50}
                speed={1.0}
                options={defaultOptions}
                isStopped={animationState.isStopped}
                isPaused={animationState.isPaused}
            />
        </div>
    )
}
