import React, { useEffect, useState } from "react";
import "./HomeOverlayParticles.scss";
import Particles from "react-particles-js";
import Variables from "../../../variables.module.scss";

const redlabRed = Variables["redlab-red"];

export default function HomeOverlayParticles() {
  const [numParticles, setNumParticles] = useState(window.innerWidth / 20);

  useEffect(() => {
    function handleResize() {
      setNumParticles(window.innerWidth / 20);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Particles
      className="HomeOverlayParticles"
      params={{
        particles: {
          number: {
            value: numParticles
          },
          size: {
            value: 3
          },
          color: {
            value: redlabRed
          },
          line_linked: {
            color: {
              value: redlabRed
            }
          }
        },
        interactivity: {
          events: {
            onhover: {
              enable: true,
              mode: "repulse"
            }
          }
        }
      }}
    />
  );
}
