import React, { useEffect, useState } from 'react';
import "./Busto3D.scss";
import { Group, MathUtils, Object3D, Vector3 } from "three";
import { useFrame, useThree } from "react-three-fiber";
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { BustoToolsParams } from '../../../types/BustoToolsVO';

type Busto3DProps = {
  params:BustoToolsParams,
  resetPosition?:number,
  dragPosX?:number,
  scrollPosY?:number
}

type RedObject = {
  id:string,
  speed:number,
  object:Object3D,
  allowRotation:boolean,
  resetRotation:number | null
}

export default function Busto3D(props:Busto3DProps) {
  const { invalidate } = useThree();
  const [objects, setObjects] = useState<RedObject[]>([]);
  const [isResetting, setIsResetting] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [isWheeling, setIsWheeling] = useState<boolean>(false);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [radians, setRadians] = useState<number>(0.0);
  const [resetValue, setResetValue] = useState<number>(0.0);
  const [lastResetValue, setLastResetValue] = useState<number>(0.0);
  const rotationAxis:Vector3 = new Vector3(0, 1, 0);
  const targetRotation:number = 0.0;

  function loadObjects(aId:string, aAllowRotation:boolean, aLayer:number, aSpeed:number, aCastShadows:boolean, aReceiveShadows:boolean, aURL:string, aMaterial:MTLLoader.MaterialCreator) {
    new OBJLoader().setMaterials(aMaterial).load(aURL, (aObject:Group) => {
      // eslint-disable-next-line
      aObject.rotation.set(0.0, 0.0, 0.0);
      // eslint-disable-next-line
      aObject.castShadow = aCastShadows;
      // eslint-disable-next-line
      aObject.receiveShadow = aReceiveShadows;
      // eslint-disable-next-line
      aObject.layers.set(aLayer);
      aObject.children.forEach((aChildObject:Object3D) =>  {
        if(aChildObject != null) {
          // eslint-disable-next-line
          aChildObject.castShadow = aCastShadows;
          // eslint-disable-next-line
          aChildObject.receiveShadow = aReceiveShadows;
          // eslint-disable-next-line
          aChildObject.layers.set(aLayer);
        }
      });
      const theObject:RedObject = {
        id: aId,
        speed: aSpeed,
        object: aObject,
        allowRotation: aAllowRotation,
        resetRotation: null
      };
      setObjects((prevArray:RedObject[]) => [...prevArray, theObject]);
    });
  }

  useEffect(() => {
    new MTLLoader().load("assets/busto/busto.mtl", aMaterial => {
      aMaterial.preload();
      loadObjects("busto", true, 2.0, 1.0, true, false, "assets/busto/busto.obj", aMaterial);
    })
  }, []);

  useEffect(() => {
    if(!isResetting && props.params.scene.autoRotateObjects && !isDragging && !isScrolling && !isWheeling) {
      var theRadians:number = props.params.scene.objectRotationRate;
      if(props.params.scene.invalidateFrameloop) {
        invalidate();
      }
      setRadians(theRadians);
    } else if(!props.params.scene.autoRotateObjects) {
      setRadians(0.0);
    }
  }, [props.params.scene.autoRotateObjects]);

  useEffect(() => {
    if(!isResetting && props.dragPosX !== undefined && Math.abs(props.dragPosX) > 0) {
      var theRadians:number = -props.dragPosX / 5.0;
      if(props.params.scene.invalidateFrameloop) {
        invalidate();
      }
      setIsDragging(true);
      setRadians(theRadians);
    } else {
      setIsDragging(false);
      if(!props.params.scene.autoRotateObjects) {
        setRadians(0.0);
      }
    }
  }, [props.dragPosX]);

  useEffect(() => {
    if(!isResetting && props.scrollPosY !== undefined  && Math.abs(props.scrollPosY) > 0) {
      var theRadians:number = props.scrollPosY / 500.0;
      if(props.params.scene.invalidateFrameloop) {
        invalidate();
      }
      setIsScrolling(true);
      setRadians(theRadians);
    }
  }, [props.scrollPosY]);

  useEffect(() => {
    if(props.params.scene.invalidateFrameloop) {
      invalidate();
    }
    setIsResetting(props.resetPosition !== undefined && props.resetPosition >= 0.0 && props.resetPosition <= 1.0);
    setResetValue(props.resetPosition??0.0);
  }, [props.resetPosition]);

  useFrame(() => {
    var needsUpdate = false;
    objects.forEach((object:RedObject) => {
      if(object.allowRotation) {
        if(isResetting) {
          if(resetValue !== lastResetValue || resetValue === 1.0) {
            if(lastResetValue === 0.0) {
              // eslint-disable-next-line
              object.resetRotation = object.object.rotation.clone().y;
            }
            returnToInitialPosition(object, resetValue);
            needsUpdate = resetValue !== lastResetValue;
          }
        } else if (Math.abs(radians) > 0.0 && object.speed > 0.0){
          const angle:number = radians * object.speed;
          rotateAroundWorldAxis(object, angle);
          needsUpdate = true;
        }
        if(!isResetting && object.resetRotation !== null) {
          // eslint-disable-next-line
          object.resetRotation = null;
        }
      }
    });
    if(resetValue !== lastResetValue) {
      setLastResetValue(resetValue);
    }
    if(resetValue === 0.0) {
      setIsResetting(false);
    }
    if(isScrolling) {
      setIsScrolling(false);
    }
    if(isWheeling) {
      setIsWheeling(false);
    }
    if(needsUpdate && props.params.scene.invalidateFrameloop) {
      invalidate();
    }
  });

  function rotateAroundWorldAxis(aObject:RedObject, aRadians:number) {
    aObject.object.rotateOnWorldAxis(rotationAxis, aRadians);
  }

  function returnToInitialPosition(aObject:RedObject, aStep:number) {
    if(aObject.resetRotation) {
      const currentRotation = aObject.object.rotation.y;
      const newRotation:number = MathUtils.lerp(currentRotation, targetRotation, aStep);
      aObject.object.rotation.set(0, newRotation, 0);
    }
  }

  return (
    <group rotation={[0.0, -0.4, 0.0]} position={[0.0, 0.0, 0.0]} scale={[0.85, 0.85, 0.85]}>
      { objects.map( (redObject:RedObject) =>
        <primitive 
          key={`Busto3D_primitive_${redObject.object.id}`}
          object={redObject.object}
        />
      )}
    </group>
  );
}
