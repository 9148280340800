import React, { useEffect, useRef } from "react";
import "./LightHelper.scss";
import { FontLoader, Mesh, Vector3 } from "three";
import fontRoboto from "../../../../assets/fonts/OpenSans.json";
import Variables from "../../../../variables.module.scss";

const redlabHelperSpot = Variables["redlab-helper-spot"];
const redlabHelperPoint = Variables["redlab-helper-point"];

export enum LightType {
    AMBIENT,
    POINT,
    SPOT
}

type LightHelperProps = {
    id:string,
    type:LightType,
    position:Vector3 | [number, number, number],
    lookAt?:Vector3 | [number, number, number]
}

export default function LightHelper(props:LightHelperProps) {
    const refLight = useRef<Mesh>();
    const refText = useRef<Mesh>()
    const refLookAt = useRef<Mesh>()
    const font = new FontLoader().parse(fontRoboto);
    const textOptions = {
      font,
      size: 5,
      height: 1
    };
    const enableLookAtHelper:boolean = true;
    const color:string = props.type === LightType.SPOT ? redlabHelperSpot : redlabHelperPoint;

    useEffect(() => {
        refLight.current?.layers.set(3);
    }, [refLight.current])

    useEffect(() => {
        refText.current?.layers.set(3);
    }, [refText.current])

    useEffect(() => {
        refLookAt.current?.layers.set(3);
    }, [refLookAt.current])

    useEffect(() => {
        setDirection();
    }, [props.position, props.lookAt])

    function setDirection() {
        if(props.lookAt && refLight && refLight.current) {
            const position = (props.position instanceof Vector3) ? props.position.clone() : new Vector3(props.position[0], props.position[1], props.position[2]);
            const lookAt = (props.lookAt instanceof Vector3) ? props.lookAt.clone() : new Vector3(props.lookAt[0], props.lookAt[1], props.lookAt[2]);
            const direction = new Vector3().subVectors(position, lookAt).normalize();
            
            if ( direction.y > 0.99999 ) {
                refLight.current.quaternion.set( 0, 0, 0, 1 );
            } else if ( direction.y < - 0.99999 ) {
                refLight.current.quaternion.set( 1, 0, 0, 0 );
            } else {
                const axis = new Vector3();
                axis.set( direction.z, 0, - direction.x ).normalize();
                const radians = Math.acos( direction.y );
                refLight.current.quaternion.setFromAxisAngle( axis, radians );
            }
        }
	}

    return (<>
        <mesh ref={refLight} position={props.position}>
            {props.type === LightType.SPOT ? 
                <coneBufferGeometry
                    attach="geometry"
                    args={[0.7, 1]}
                /> :
                <sphereGeometry
                    attach="geometry"
                    args={[0.4, 5.0, 4.0]}
                />
            },
            <meshBasicMaterial 
                attach="material"
                color={color}
                opacity={1.0}
                reflectivity={0.0}
                wireframe
            />
        </mesh>
        <mesh ref={refText}  position={props.position} scale={[0.05, 0.05, 0.05]}>
            <textGeometry attach='geometry' args={[props.id, textOptions]} />
            <meshBasicMaterial
                attach="material"
                color={color}
                opacity={1.0}
                reflectivity={0.0}
            />
        </mesh>
        {!enableLookAtHelper || !props.lookAt ? null :
            <mesh ref={refLookAt}  position={props.lookAt} scale={[0.05, 0.05, 0.05]}>
                <textGeometry attach='geometry' args={[props.id, textOptions]} />
                <meshBasicMaterial
                    attach="material"
                    color={color}
                    opacity={1.0}
                    reflectivity={0.0}
                />
            </mesh>
        }
    </>
    )
}
