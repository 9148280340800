import React, { useEffect, useState } from "react";
import "./App.scss";
import ReactGA from "react-ga";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  getLanguage,
  translate
} from "react-switch-lang";
import { useMediaQuery } from "react-responsive";
import { WEBGL } from "three/examples/jsm/WebGL";
import { allowWebGL } from "./Constants";
import Routes, { getRouteLocation, getRouteName } from "./types/Routes";
import Consent from "./components/Consent/Consent";
import Home from "./components/Home/Home";
import HomeFallback from "./components/Home/HomeFallback";
import Footer from "./components/Footer/Footer";
import Services from "./components/Services/Services";
import ServicesMobile from "./components/ServicesMobile/ServicesMobile";
import Trailer from "./components/Trailer/Trailer";
import Technologies from "./components/Technologies/Technologies";
import Form from "./components/Form/Form";
import Variables from "./variables.module.scss";
import RedScroll from "./components/Common/RedScroll/RedScroll";
import About from "./components/About/About";
import ServicesPageHero from "./components/ServicesPage/ServicesPageHero/ServicesPageHero";
import ServicesPageItems from "./components/ServicesPage/ServicesPageItems/ServicesPageItems";
import pt from "./data/lang/pt.json";
import en from "./data/lang/en.json";
import Menu from "./components/Menu/Menu";

const redlabBackgroundDark = Variables["redlab-background-dark"];
const redlabBackgroundGrey = Variables["redlab-background-grey"];
const mobileBreakpoint = Variables["redlab-breakpoint1"];
const availableLanguages = { pt, en };
const availableLanguagesKeys = Object.keys(availableLanguages);
const defaultLanguage = "pt";
setTranslations(availableLanguages);
setDefaultLanguage(defaultLanguage);
setLanguageCookie("RedLabLang");

const DEFAULT_CONFIG = {
  trackingId: "UA-193041609-1", // G-LG3Q37JYZL
  debug: false,
  gaOptions: {
    cookieDomain: "none"
  }
};

function App(props: any) {
  const isMobile = useMediaQuery({
    query: `(max-width: ${mobileBreakpoint})`
  });
  const [currentLanguage, setCurrentLanguage] = useState(defaultLanguage);

  useEffect(() => {
    ReactGA.initialize(DEFAULT_CONFIG.trackingId, {
      debug: DEFAULT_CONFIG.debug,
      gaOptions: DEFAULT_CONFIG.gaOptions
    });
    ReactGA.pageview("/redlab");
    setLanguageCookie("RedLabLang");
    setCurrentLanguage(getLanguage());
  }, []);

  function onLanguageSwitchHandler(newLanguage: string) {
    if (newLanguage !== currentLanguage) {
      setLanguage(newLanguage);
      setCurrentLanguage(newLanguage);
    }
  }

  return (
    <Router>
      <div className="App">
        <div className="AppMenu">
          <Switch>
            <Route exact path={getRouteLocation(Routes.SERVICES_PAGE)}>
              <Menu
                parentRoute={Routes.SERVICES_PAGE}
                t={props.t}
                onLanguageSwitch={onLanguageSwitchHandler}
                availableLanguages={availableLanguagesKeys}
                currentLanguage={currentLanguage}
              />
            </Route>
            <Route exact path={getRouteLocation(Routes.HOME)}>
              <Menu
                parentRoute={Routes.HOME}
                t={props.t}
                onLanguageSwitch={onLanguageSwitchHandler}
                availableLanguages={availableLanguagesKeys}
                currentLanguage={currentLanguage}
              />
            </Route>
          </Switch>
        </div>
        <div className="AppBody">
          <Switch>
            <Route exact path={getRouteLocation(Routes.SERVICES_PAGE)}>
              <RedScroll>
                <Consent t={props.t} />
                <section id={getRouteName(Routes.TOP)}>
                  <ServicesPageHero t={props.t} />
                </section>
                <section id={getRouteName(Routes.SERVICES)}>
                  <ServicesPageItems t={props.t} />
                </section>
                <section id={getRouteName(Routes.CONTACTS)}>
                  <Form
                    t={props.t}
                    currentLanguage={currentLanguage}
                    style={{ backgroundColor: redlabBackgroundDark }}
                  />
                  <Footer
                    t={props.t}
                    style={{ backgroundColor: redlabBackgroundDark }}
                  />
                </section>
              </RedScroll>
            </Route>
            <Route exact path={getRouteLocation(Routes.HOME)}>
              <RedScroll>
                <Consent t={props.t} />
                <section id={getRouteName(Routes.TOP)}>
                  {allowWebGL && WEBGL.isWebGLAvailable() ? (
                    <Home isMobile={isMobile} t={props.t} />
                  ) : (
                    <>
                      <HomeFallback t={props.t} />
                      <section id={getRouteName(Routes.ABOUT)}>
                        <About
                          t={props.t}
                          style={{ height: "auto", padding: "15vw 0 15vw 0" }}
                        />
                      </section>
                    </>
                  )}
                </section>
                <div
                  className={
                    isMobile || !allowWebGL || !WEBGL.isWebGLAvailable()
                      ? "GradientSeparatorInvFallback"
                      : "GradientSeparatorInv"
                  }
                />
                <section id={getRouteName(Routes.SERVICES)}>
                  {isMobile ? (
                    <ServicesMobile t={props.t} style={{ height: "675px" }} />
                  ) : (
                    <Services t={props.t} style={{ height: "675px" }} />
                  )}
                  <div
                    className={
                      isMobile || !allowWebGL || !WEBGL.isWebGLAvailable()
                        ? "GradientSeparatorFallback"
                        : "GradientSeparator"
                    }
                  />
                </section>
                <section id={getRouteName(Routes.INFO)}>
                  <Technologies isMobile={isMobile} t={props.t} />
                  <Trailer currentLanguage={currentLanguage} />
                </section>
                <section id={getRouteName(Routes.CONTACTS)}>
                  <Form t={props.t} currentLanguage={currentLanguage} />
                  <Footer
                    t={props.t}
                    style={{ backgroundColor: redlabBackgroundGrey }}
                  />
                </section>
              </RedScroll>
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

App.propTypes = {
  t: PropTypes.func.isRequired
};

export default translate(App);
