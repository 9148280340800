export const routePath: string = "/";
export const imagesPath: string = "/assets/images/";
export const videosPath: string = "/assets/videos/";
export const legalPath: string = "/assets/legal/";

export const allowWebGL: boolean = true;
export const allowVideos: boolean = true;
export const allowRecaptcha: boolean = true;

export default imagesPath;
