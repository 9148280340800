import React, { useState } from "react";
import "./RedVideo.scss";
import { useMediaQuery } from "react-responsive";
import ReactPlayer from "react-player";
import { allowVideos, videosPath } from "../../../Constants";
import RedImage from "../RedImage/RedImage";
import Variables from "../../../variables.module.scss";

const videoBreakpoint = Variables["redlab-breakpoint1"];

type RedVideoProps = {
  isPlaying?: boolean;
  className?: string;
  src: string;
  srcPortrait?: string;
  fallbackImage?: string;
  controls: boolean;
};

export default function RedVideo(props: RedVideoProps) {
  const [supportsVideo, setSupportsVideo] = useState<boolean>(allowVideos);
  const isPortrait = useMediaQuery({
    query: `(max-width: ${videoBreakpoint})`
  });

  function onVideoErrorHandler(
    error: any,
    data?: any,
    hlsInstance?: any,
    hlsGlobal?: any
  ) {
    setSupportsVideo(false);
    console.log(error);
  }

  if (supportsVideo) {
    const videoSrc =
      props.srcPortrait !== undefined && isPortrait
        ? props.srcPortrait
        : props.src;
    return (
      <ReactPlayer
        url={`${videosPath}${videoSrc}`}
        className={props.className ? props.className : "RedVideo"}
        loop
        muted
        width="100%"
        height="100%"
        volume={0}
        controls={props.controls}
        playing={props.isPlaying}
        onError={onVideoErrorHandler}
      />
    );
  }

  if (props.fallbackImage) {
    return (
      <div className={props.className ? props.className : "RedVideo"}>
        <RedImage src={props.fallbackImage} alt="RedLab Video Fallback" />
      </div>
    );
  }

  return <div className="RedVideoFallback" />;
}
