import React, { useEffect, useRef, useState } from "react";
import "./Home.scss";
import ReactGA from "react-ga";
import { MathUtils } from "three";
import { useLocation } from "react-router-dom";
import VizSensor from "react-visibility-sensor";
import InputControl from "../Common/Controls/InputControl";
import HomeOverlayParticles from "./HomeOverlayParticles/HomeOverlayParticles";
import RedImage from "../Common/RedImage/RedImage";
import BustoCanvas from "../Busto/BustoCanvas";
import HomeWheel from "./HomeWheel/HomeWheel";
import { MTiltText } from "../Common/TiltText/TiltText";
import About from "../About/About";
import Routes, { getRouteSection } from "../../types/Routes";
import { imagesPath } from '../../Constants';

type HomeProps = {
  isMobile?: boolean;
  t: Function;
};

export default function Home(props: HomeProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [domElement, setDOMElement] = useState<HTMLDivElement | null>(null);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [isAnimating, setIsAnimating] = useState<boolean>(true);
  const [dragPosX, setDragPosX] = useState<number>(0.0);
  const [dragPosY, setDragPosY] = useState<number>(0.0);
  const [wheelY, setWheelY] = useState<number>(0.0);
  const [scrollY, setScrollY] = useState<number>(0.0);
  const [resetPosition, setResetPosition] = useState(
    isVisible && window.scrollY === 0.0 ? 0.0 : 1.0
  );
  const [animationPosition, setAnimationPosition] = useState(
    isVisible && window.scrollY === 0.0 ? 0.0 : 1.0
  );
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview('/redlab/home');
  }, []);

  useEffect(() => {
    setDOMElement(ref.current);
  }, [ref.current]);

  useEffect(() => {
    if (location.hash === getRouteSection(Routes.TOP)) {
      setResetPosition(0.0);
      setAnimationPosition(0.0);
      setIsAnimating(true);
      setIsActive(true);
    } else if (location.hash === getRouteSection(Routes.ABOUT)) {
      setResetPosition(1.0);
      setAnimationPosition(2.0);
      setIsAnimating(false);
      setIsActive(false);
    }
  }, [location]);

  useEffect(() => {
    if (isVisible && window.scrollY === 0.0) {
      setResetPosition(0.0);
      setAnimationPosition(0.0);
    }
  }, [isVisible]);

  useEffect(() => {
    updateResetPosition(dragPosY * -1);
  }, [dragPosY]);

  useEffect(() => {
    updateResetPosition(Math.sign(wheelY) / 4);
  }, [wheelY]);

  useEffect(() => {
    if (Math.sign(scrollY) > 0.0) {
      updateResetPosition(Math.sign(scrollY) / 10, true);
    }
  }, [scrollY]);

  function updateResetPosition(value: number, isScrolling: boolean = false) {
    var newResetPosition = 1.0;
    var newAnimationPosition = 2.0;

    if (window.scrollY === 0.0 || isScrolling) {
      newAnimationPosition = parseFloat(
        MathUtils.clamp(animationPosition + value, 0.0, 2.0).toFixed(2)
      );

      if (newAnimationPosition === 0.0) {
        newResetPosition = 0.0;
        if (!isActive) {
          setIsActive(true);
        }
        if (!isAnimating) {
          setIsAnimating(true);
        }
      } else if (newAnimationPosition <= 1.0) {
        newResetPosition = parseFloat(
          MathUtils.clamp(resetPosition + value, 0.0, 1.0).toFixed(2)
        );
      } else {
        if (newAnimationPosition <= 1.2 && !isAnimating) {
          setIsAnimating(true);
        } else if (newAnimationPosition > 1.2 && isAnimating) {
          setIsAnimating(false);
        }
        if (newAnimationPosition === 2.0 && isActive) {
          setIsActive(false);
        } else if (newAnimationPosition < 2.0 && !isActive) {
          setIsActive(true);
        }
      }
    } else {
      if (isAnimating) {
        setIsAnimating(false);
      }
      if (isActive) {
        setIsActive(false);
      }
    }

    setResetPosition(newResetPosition);
    setAnimationPosition(newAnimationPosition);
  }

  function onScrollResetHandler() {
    setIsActive(true);
  }

  function onMoveHandler(
    aDragX: number,
    aDragY: number,
    pageX: number,
    pageY: number
  ) {
    setDragPosX(aDragX);
  }

  return (
    <VizSensor partialVisibility onChange={setIsVisible}>
      <div ref={ref} className="Home">
        <InputControl
          key="MInputControl"
          domElement={domElement}
          isActive={isActive}
          isVisible={isVisible}
          onScrollReset={onScrollResetHandler}
          onMove={onMoveHandler}
          onScrollY={setScrollY}
          onDragMoveY={setDragPosY}
          onDragMoveX={setDragPosX}
          onWheelY={setWheelY}
        />
        <div className="HomeOverlay">
          <div className="HomeLogo">
            <img src={`${imagesPath}logo.svg`} alt="RedLab" />
          </div>
          <div
            className={
              isAnimating ? "HomeText HomeFadeIn" : "HomeText HomeFadeOut"
            }
          >
            <div className="HomeTextContent">
              <MTiltText
                text={[
                  {
                    key: "home.text1",
                    text: props.t("home.text1"),
                    initPosY: -window.innerHeight
                  },
                  {
                    key: "home.text2",
                    text: props.t("home.text2"),
                    initPosY: -window.innerHeight
                  }
                ]}
                subtext={[
                  {
                    key: "home.text3",
                    text: props.t("home.text3"),
                    initPosY: -window.innerHeight
                  }
                ]}
              />
            </div>
          </div>
          <div
            className={
              isAnimating
                ? "HomeWheelContent HomeFadeIn"
                : "HomeWheelContent HomeFadeOut"
            }
          >
            <div className="HomeWheelText">{props.t("home.text4")}</div>
            <HomeWheel />
          </div>
          <div className="HomeOverlayObjects">
            <HomeOverlayParticles />
          </div>
        </div>
        <div
          className={
            !isAnimating ? "HomeAbout HomeFadeIn" : "HomeAbout HomeFadeOut"
          }
        >
          {!isAnimating ? <About t={props.t} /> : null}
        </div>
        <div className="HomeContent">
          <BustoCanvas
            isMobile={props.isMobile}
            resetPosition={resetPosition}
            dragPosX={dragPosX}
          />
        </div>
      </div>
    </VizSensor>
  );
}
