import React, { useEffect, useState } from "react";
import "./TiltText.scss";
import { MathUtils } from "three";
import { RedText } from "../../../types/RedText";
import { MTiltTextItem } from "./TiltTextItem/TiltTextItem";

type TiltTextProps = {
  text: RedText[];
  subtext?: RedText[];
};

export default function TiltText(props: TiltTextProps) {
  const [tilt, setTilt] = useState<number[]>([0, 0, 0]);
  const [mouse, setMouse] = useState<number[]>([0, 0]);
  const mouseDeltaThreshold = 1.0;

  useEffect(() => {
    window.addEventListener("pointermove", onPointerMove, true);
    return () => {
      window.removeEventListener("pointermove", onPointerMove, true);
    };
  }, []);

  useEffect(() => {
    if (
      Math.abs(mouse[0] - tilt[0]) > mouseDeltaThreshold ||
      Math.abs(mouse[1] - tilt[1]) > mouseDeltaThreshold
    ) {
      const tiltOutside =
        Math.abs(mouse[0]) === 40 || Math.abs(mouse[1]) === 25;
      const tiltEase = tiltOutside ? 1 : tilt[2] - 0.03;
      setTilt([
        tiltOutside ? 0 : mouse[0] * -0.3,
        tiltOutside ? 0 : mouse[1] * 0.75,
        tiltEase < 0 ? 0 : tiltEase
      ]);
    }
  }, [mouse]);

  function onPointerMove(event: PointerEvent) {
    const deltaX =
      ((event.clientX - window.innerWidth / 2) / window.innerWidth) * 100;
    const deltaY =
      ((event.clientY - window.innerHeight / 2) / window.innerHeight) * 100;
    setMouse([
      MathUtils.clamp(deltaX, -40, 40),
      MathUtils.clamp(deltaY, -25, 25)
    ]);
  }

  return (
    <div
      className="TiltTextItems"
      style={{
        transform: `perspective(400px) rotateY(${tilt[0]}deg) rotateX(${tilt[1]}deg)`,
        transition: `transform ${tilt[2]}s ease-out 0s`
      }}
    >
      {props.text.map((redText: RedText, index: number) => {
        return (
          <MTiltTextItem key={`TiltTextItem_${redText.key}`} text={redText} />
        );
      })}
      {props.subtext &&
        props.subtext.map((redText: RedText, index: number) => {
          return (
            <MTiltTextItem
              key={`TiltTextItem_${redText.key}`}
              text={redText}
              isSubText={true}
            />
          );
        })}
    </div>
  );
}

export const MTiltText = React.memo(TiltText, MTiltTextCheck);
function MTiltTextCheck(prevProps: TiltTextProps, nextProps: TiltTextProps) {
  return prevProps.text === nextProps.text;
}
