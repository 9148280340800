import React, { useEffect, useRef, useState } from "react";
import "./BustoCanvas.scss";
import { VSMShadowMap, ReinhardToneMapping, Shape, Vector2, Vector3, MathUtils } from "three";
import { Canvas, CanvasContext } from "react-three-fiber";
import { BustoToolsParams } from "../../types/BustoToolsVO";
import defaultParamsBusto from "../../data/params/defaultParamsBusto";
import Lights from "../Common/Lights/Lights";
import CameraControl from "../Common/Controls/CameraControl";
import Busto3D from "./Busto3D/Busto3D";
import BustoEffects from "./BustoEffects/BustoEffects";
import CommonHelpers from "../Common/Tools/CommonHelpers/CommonHelpers";
import FpsDisplay from "../Common/FPS/FpsDisplay";
import BustoTools from "./BustoTools/BustoTools";

type BustoCanvasProps = {
  isMobile?:boolean;
  resetPosition?:number
  dragPosX?:number
}

export default function BustoCanvas(props:BustoCanvasProps) {
  const canvasDom = useRef<HTMLDivElement>(null);
  const [canvas, setCanvas] = useState<CanvasContext>();
  const [windowSize, setWindowSize] = useState(new Vector2(0.0, 0.0));
  const [aspectRatio, setAspectRatio] = useState(0.0);
  const [params, setParams] = useState<BustoToolsParams>(defaultParamsBusto);
  const cameraInitPos = new Vector3(params.cameraStart.positionX, params.cameraStart.positionY, params.cameraStart.positionZ);
  const cameraEndPos = new Vector3(params.cameraEnd.positionX, params.cameraEnd.positionY, params.cameraEnd.positionZ);
  const cameraInitPosMobile = new Vector3(params.cameraStartMobile.positionX, params.cameraStartMobile.positionY, params.cameraStartMobile.positionZ);
  const cameraEndPosMobile = new Vector3(params.cameraEndMobile.positionX, params.cameraEndMobile.positionY, params.cameraEndMobile.positionZ);
  const [cameraPosition, setCameraPosition] = useState<Vector3>(props.isMobile ? cameraInitPos : cameraInitPosMobile);

  useEffect(() => {
      function handleResize() {
        if(canvasDom && canvasDom.current) {
          setWindowSize(new Vector2(canvasDom.current.clientWidth, canvasDom.current.clientHeight));
        }
      }
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize)
      }
  }, []);

  useEffect(() => {
    setCameraPosition(new Vector3(params.cameraStart.positionX, params.cameraStart.positionY, params.cameraStart.positionZ));
  }, [params.cameraStart])
  
  useEffect(() => {
    const newCameraInitPosition = props.isMobile ? cameraInitPosMobile : cameraInitPos;
    const newCameraEndPosition = props.isMobile ? cameraEndPosMobile : cameraEndPos;
    var newCameraPosition = newCameraEndPosition;
    if (!props.resetPosition || props.resetPosition === 0.0) {
      newCameraPosition = newCameraInitPosition;
    } else if (props.resetPosition < 1.0) {
      newCameraPosition = newCameraInitPosition.lerp(newCameraEndPosition, props.resetPosition);
    } else {
      newCameraPosition = newCameraEndPosition;
    }
    setCameraPosition(newCameraPosition);
  }, [props.resetPosition, props.isMobile])

  function onCanvasCreated(canvas: CanvasContext) {
    setCanvas(canvas);
    // eslint-disable-next-line
    canvas.gl.autoClear = false;
    // eslint-disable-next-line
    canvas.gl.toneMapping = ReinhardToneMapping;
    // eslint-disable-next-line
    canvas.gl.shadowMap.enabled = true;
    // eslint-disable-next-line
    canvas.gl.shadowMap.type = VSMShadowMap;
    // eslint-disable-next-line
    canvas.gl.shadowMap.autoUpdate = params.shadows.autoUpdate;
    // eslint-disable-next-line
    canvas.gl.shadowMapDebug = false;
    
    canvas.gl.setClearAlpha(0);
    
    if(canvasDom && canvasDom.current) {
      canvas.gl.setSize(canvasDom.current.clientWidth, canvasDom.current.clientHeight);
      setWindowSize(new Vector2(canvasDom.current.clientWidth, canvasDom.current.clientHeight));
    }
  }

  return (
  <div ref={canvasDom} className="Busto3DCanvas">
    <Canvas
      onCreated={onCanvasCreated}
      style={{touchAction: "pan-y"}}
      resize={{scroll: false}}
      invalidateFrameloop={params.scene.invalidateFrameloop}
      shadowMap
    >
      <scene name="base">
        <perspectiveCamera
          args={[params.cameraStart.fov, aspectRatio, params.cameraStart.near, params.cameraStart.far]}
          position={cameraPosition}
        >
            <CameraControl params={props.isMobile ? params.cameraStartMobile : params.cameraStart} windowSize={windowSize}/>
            <Lights ambientLight={params.ambientLight} spotlights={params.spotlights} pointlights={params.pointlights} shadows={params.shadows}/>
            <Busto3D params={params} resetPosition={props.resetPosition} dragPosX={props.dragPosX} />
            <BustoEffects/>
        </perspectiveCamera>
      </scene>
      { params.enableTools === false || 
        (params.helpers.enableAxisHelper === false && params.helpers.enableLightHelpers === false) ? null :
        <CommonHelpers helpers={params.helpers} spotlights={params.spotlights} pointlights={params.pointlights} />
      }
    </Canvas>
    { params.enableTools === false ? null :
      <BustoTools params={params} updateParams={setParams}/>
    }
    { params.enableTools === false ? null :
      <FpsDisplay/>
    }
  </div>
  );
}
