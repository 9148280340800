import React, { CSSProperties, useEffect, useState } from "react";
import "./ServicesMobile.scss";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import ItemsCarousel from "react-items-carousel";
import { ServicesVO } from "../../types/ServicesVO";
import ServicesMobileData from "../../data/content/services.json";
import ServicesMobileListItem from "./ServicesMobileListItem/ServicesMobileListItem";
import ServicesMobileItemImage from "./ServicesMobileImage/ServicesMobileImage";
import { imagesPath } from "../../Constants";
import Routes, { getRouteLink } from "../../types/Routes";

const data: ServicesVO[] = ServicesMobileData;

type ServicesMobileProps = {
  t: Function;
  style?: CSSProperties;
};

const speedServicesMobile = 2000;

export default function ServicesMobile(props: ServicesMobileProps) {
  const [isSelecting, setIsSelecting] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  useEffect(() => {
    data.forEach((service:ServicesVO) => {
        new Image().src = imagesPath + service.icon;
    });
  }, [])
  
  useEffect(() => {
    var intervalId: any = null;
    if (activeIndex !== selectedIndex) {
      var newIndex = selectedIndex;
      if (newIndex >= data.length) {
        newIndex = 0;
      } else if (newIndex < 0) {
        newIndex = data.length - 1;
      }
      setSelectedIndex(newIndex);
      setActiveIndex(newIndex);
    } else if (!isSelecting) {
      intervalId = setInterval(() => {
        if (!isSelecting) {
          setSelectedIndex(activeIndex + 1);
        }
      }, speedServicesMobile);
    }
    return () => {
      if (intervalId != null) {
        clearInterval(intervalId);
      }
    };
  }, [activeIndex, selectedIndex, isSelecting]);

  function onItemSelectingHandler(aItem: ServicesVO) {
    setIsSelecting(true);
  }

  function onItemSelectedHandler(aItem: ServicesVO) {
    setIsSelecting(false);
  }

  return (
    <div className="ServicesMobile" style={props.style}>
      <div className="ServicesMobileBackground">
        <ServicesMobileItemImage
          key="ServicesMobileItemImage"
          item={data[activeIndex]}
          title={props.t(data[activeIndex].title)}
        />
      </div>
      <div className="ServicesMobileContent">
        <div className="ServicesMobileBody">
          <div className="ServicesMobileRows">
            <div className="ServicesMobileRow1">
              <ItemsCarousel
                className="ServicesMobileListContent"
                requestToChangeActive={setSelectedIndex}
                activeItemIndex={activeIndex}
                numberOfCards={1}
                infiniteLoop={true}
                gutter={window.innerWidth}
              >
                {data && data.length > 0
                  ? data.map(
                      (item: ServicesVO, index: number, arr: ServicesVO[]) => {
                        return (
                          <ServicesMobileListItem
                            key={`ServicesMobileListItem_${item.index}`}
                            item={item}
                            title={props.t(item.title)}
                            isSelected={activeIndex === index}
                            onItemSelecting={onItemSelectingHandler}
                            onItemSelected={onItemSelectedHandler}
                          />
                        );
                      }
                    )
                  : null}
              </ItemsCarousel>
            </div>
            <div className="ServicesMobileRow2" />
            <div className="ServicesMobileRow3">
              <div className="ServicesInfoContent">
                <div className="ServicesInfoTitle">
                  <div>{props.t("services.infoTitle")}</div>
                </div>
                <div className="ServicesInfoText">
                  <div>{props.t("services.infoText")}</div>
                </div>
                <div className="ServicesInfoButton">
                  <Link to={getRouteLink(Routes.SERVICES_PAGE)}>
                    <div className="ServicesInfoButtonContent">
                      <div>{props.t("services.infoButton")}</div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
