import React, { useEffect, useState } from "react";
import "./Menu.scss";
import { HashLink } from "react-router-hash-link";
import OffCanvas from "react-aria-offcanvas";
import { GiHamburgerMenu } from "react-icons/gi";
import Routes, { getRouteLink, getRouteLocation, getRouteSection } from "../../types/Routes";
import { ServicesVO } from "../../types/ServicesVO";
import ServicesData from "../../data/content/services.json";

const data: ServicesVO[] = ServicesData;

type MenuProps = {
  parentRoute: Routes;
  onLanguageSwitch: Function;
  availableLanguages: string[];
  currentLanguage: string;
  t: Function;
};

export default function Menu(props: MenuProps) {
  const [isOpen, setIsOpen] = useState(false);

  function onLanguageSwitchHandler(aLanguage: string) {
    setIsOpen(false);
    if (props.onLanguageSwitch) {
      props.onLanguageSwitch(aLanguage);
    }
  }

  function onSectionClickHandler() {
    setIsOpen(false);
  }

  return (
    <div className="Menu">
      <GiHamburgerMenu
        className="MenuIcon"
        size="35px"
        onClick={() => setIsOpen(!isOpen)}
      />
      <OffCanvas
        lockBodyAfterOpen={false}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        overlayClassName={
          isOpen ? "MenuOverlay MenuFadeIn" : "MenuOverlay MenuFadeOut"
        }
        className="MenuCanvas"
        position="top"
      >
        <GiHamburgerMenu
          className="MenuIcon"
          size="35px"
          onClick={() => setIsOpen(!isOpen)}
        />
        <div className="MenuContent">
          <div className="MenuItems">
            <div className="MenuItem">
              {props.availableLanguages.map((aLanguage: string) => (
                <button
                  key={aLanguage}
                  className={
                    props.currentLanguage === aLanguage
                      ? "MenuLanguageItem MenuLanguageItemSelected"
                      : "MenuLanguageItem"
                  }
                  onClick={() => onLanguageSwitchHandler(aLanguage)}
                >
                  {aLanguage}
                </button>
              ))}
            </div>
            <div className={isOpen ? "MenuList" : "MenuList MenuListHide"}>
              {props.parentRoute === Routes.HOME ? (
                <>
                  <HashLink
                    smooth
                    to={getRouteSection(Routes.TOP)}
                    className="MenuItem"
                    onClick={() => onSectionClickHandler()}
                  >
                    {props.t("routes.top")}
                  </HashLink>
                  <HashLink
                    smooth
                    to={getRouteSection(Routes.ABOUT)}
                    className="MenuItem"
                    onClick={() => onSectionClickHandler()}
                  >
                    {props.t("routes.about")}
                  </HashLink>
                  <HashLink
                    smooth
                    to={getRouteLink(Routes.SERVICES_PAGE)}
                    className="MenuItem"
                    onClick={() => onSectionClickHandler()}
                  >
                    {props.t("routes.services")}
                  </HashLink>
                  <HashLink
                    smooth
                    to={getRouteSection(Routes.INFO)}
                    className="MenuItem"
                    onClick={() => onSectionClickHandler()}
                  >
                    {props.t("routes.technologies")}
                  </HashLink>
                  <HashLink
                    smooth
                    to={getRouteSection(Routes.CONTACTS)}
                    className="MenuItem"
                    onClick={() => onSectionClickHandler()}
                  >
                    {props.t("routes.form")}
                  </HashLink>
                </>
              ) : (
                <>
                  <HashLink
                    smooth
                    to={getRouteLocation(Routes.HOME)}
                    className="MenuItem"
                    onClick={() => onSectionClickHandler()}
                  >
                    {props.t("routes.home")}
                  </HashLink>
                  {data && data.length > 0
                    ? data.map(
                        (
                          item: ServicesVO,
                          index: number,
                          arr: ServicesVO[]
                        ) => {
                          return (
                            <HashLink
                              smooth
                              to={`#${item.id}`}
                              className="MenuItem"
                              onClick={() => onSectionClickHandler()}
                              key={`MenuItem_${item.id}`}
                            >
                              {props.t(item.menu)}
                            </HashLink>
                          );
                        }
                      )
                    : null}
                  <HashLink
                    smooth
                    to={getRouteSection(Routes.CONTACTS)}
                    className="MenuItem"
                    onClick={() => onSectionClickHandler()}
                  >
                    {props.t("routes.form")}
                  </HashLink>
                </>
              )}
            </div>
          </div>
        </div>
      </OffCanvas>
    </div>
  );
}
