import React, { useEffect, useState } from "react";
import { RedText } from "../../../../types/RedText";
import "./TiltTextItem.scss";

type TiltTextItemProps = {
  text: RedText;
  isSubText?: boolean;
};

export default function TiltTextItem(props: TiltTextItemProps) {
  const [theStyle, setTheStyle] = useState({
    transform: `translate(${props.text.initPosX ?? 0.0}px, ${
      props.text.initPosY ?? 0.0
    }px)`
  });

  useEffect(() => {
    const theTimeout = window.setTimeout(() => {
      setTheStyle({
        transform: `translate(${props.text.posX ?? 0.0}px, ${
          props.text.posY ?? 0.0
        }px)`
      });
    }, 0.1);
    return () => {
      window.clearTimeout(theTimeout);
    };
  }, []);

  return (
    <div
      className={props.isSubText ? "HomeSubTextItem" : "TiltTextItem"}
      style={theStyle}
    >
      {props.text.text}
    </div>
  );
}

export const MTiltTextItem = React.memo(TiltTextItem, MTiltTextItemCheck);
function MTiltTextItemCheck(
  prevProps: TiltTextItemProps,
  nextProps: TiltTextItemProps
) {
  return prevProps.text === nextProps.text;
}
