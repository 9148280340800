import React, { useEffect } from "react";
import "./ServicesPageHero.scss";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import Routes, { getRouteLocation } from "../../../types/Routes";
import { ServicesVO } from "../../../types/ServicesVO";
import ServicesData from "../../../data/content/services.json";
import RedImage from "../../Common/RedImage/RedImage";
import RedVideo from "../../Common/RedVideo/RedVideo";
import { MTiltText } from "../../Common/TiltText/TiltText";
import { imagesPath } from '../../../Constants';

const data: ServicesVO[] = ServicesData;

type ServicesPageHeroProps = {
  t: Function;
};

export default function (props: ServicesPageHeroProps) {
  useEffect(() => {
    ReactGA.pageview('/redlab/services');
  }, []);

  return (
    <div className="ServicesPageHero">
      <div className="ServicesPageHeroOverlay">
        <div className="ServicesPageHeroLogo">
          <Link to={getRouteLocation(Routes.HOME)}>
            <img src={`${imagesPath}logo.svg`} alt="RedLab" />
          </Link>
        </div>
      </div>
      <div className="ServicesPageHeroContent">
        <div className="ServicesPageHeroContentBackground">
          <div className="ServicesPageHeroContentVideo">
            <RedVideo
              controls={false}
              src="Services.mp4"
              fallbackImage="default_background.jpg"
              isPlaying={true}
            />
          </div>
        </div>
        <div className="ServicesPageHeroContentContent">
          <div className="ServicesPageHeroContentText">
            <MTiltText
              text={[
                {
                  key: "servicesPage.title",
                  text: props.t("servicesPage.title"),
                  initPosY: -window.innerHeight
                }
              ]}
              subtext={[
                {
                  key: "servicesPage.text",
                  text: props.t("servicesPage.text"),
                  initPosY: -window.innerHeight
                }
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
