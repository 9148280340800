import React from "react";
import { Picture } from "react-responsive-picture";
import { imagesPath } from "../../../Constants";

type RedImageProps = {
  className?: string;
  src: string;
  scaled?: boolean;
  alt: string;
};

export default function RedImage(props: RedImageProps) {
  const srcSet = props.scaled
    ? `${imagesPath}${props.src} 1x, ${imagesPath}2.0x/${props.src} 2x`
    : `${imagesPath}${props.src} 1x`;
  return <Picture src={srcSet} alt={props.alt} className={props.className} />;
}
