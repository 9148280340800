import React, {useEffect, useRef} from "react";
import "./RedPointLight.scss";
import { PointLight } from "three";
import { CameraParams, PointLightParams, ShadowsParams } from "../../../../types/ToolsVO";

type RedSpotLightParams = {
    light: PointLightParams
}

export default function RedSpotLight(props:RedSpotLightParams) {
    const point = useRef<PointLight>();

    useEffect(() => {
        point.current?.layers.set(0);
        point.current?.layers.enable(1);
        point.current?.layers.enable(2);
    }, [point.current])

    return (<>
        { !props.light.enable ? null :
            <pointLight 
                ref={point} 
                args={[props.light.color, props.light.intensity, props.light.distance, props.light.decay]}
                intensity={props.light.intensity}
                position={[props.light.positionX, props.light.positionY, props.light.positionZ]}
                color={props.light.color}
                distance={props.light.distance}
                decay={props.light.decay}
            />
        }
    </>)
}
