import React, { useRef } from "react";
import "./CommonHelpers.scss";
import { Scene, WebGLRenderer } from "three";
import { useFrame, useThree } from "react-three-fiber";
import { HelpersParams, PointLightParams, SpotLightParams } from "../../../../types/ToolsVO";
import AxisHelper from "../AxisHelper/AxisHelper";
import LightsHelpers from "../LightsHelpers/LightsHelpers";

type CommonHelpersParams = {
  helpers: HelpersParams,
  spotlights:SpotLightParams[],
  pointlights:PointLightParams[]
}

export default function CommonHelpers(props:CommonHelpersParams) {
  const { camera } = useThree();
  const scene = useRef<Scene>();

  function onRenderCallback(gl:WebGLRenderer) {
    if(scene && scene.current) {
      camera.layers.set(3);
      // eslint-disable-next-line
      gl.clearDepth();
      gl.render(scene.current, camera);
    }
  }

  useFrame(({ gl }) => onRenderCallback(gl), 1000);

  return (
    <scene ref={scene} name="helpers">
      { !props.helpers.enableAxisHelper ? null :
        <AxisHelper/>
      }
      { !props.helpers.enableLightHelpers ? null :
        <LightsHelpers pointlights={props.pointlights} spotlights={props.spotlights}/>
      }
    </scene>
  );
}
