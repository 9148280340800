import React, { useEffect, useState } from "react";
import "./Technologies.scss";
import { motion } from "framer-motion";
import ItemsCarousel from "react-items-carousel";
import { TechnologiesVO } from "../../types/TechnologiesVO";
import TechnologiesData from "../../data/content/technologies.json";
import TechnologiesItem from "./TechnologiesItem/TechnologiesItem";

const data: TechnologiesVO[] = TechnologiesData;

type TechnologiesProps = {
  isMobile?: boolean;
  t: Function;
};

const speedTech = 2000;

export default function Technologies(props: TechnologiesProps) {
  const [numberOfCards, setNumberOfCards] = useState(3);
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveItemIndex(activeItemIndex + 1);
    }, speedTech);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeItemIndex]);

  useEffect(() => {
    setNumberOfCards(props.isMobile ? 1 : 3);
  }, [props.isMobile]);

  return (
    <div className="Technologies">
      <div className="TechnologiesContent">
        <div className="TechnologiesTitle">
          <div className="TechnologiesTitleContent">
            {props.t("technologies.title")}
          </div>
          <motion.div
            className="TechnologiesTitleCaret"
            key="TechnologiesTitleCaret"
            initial={{
              opacity: 0
            }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 1,
              ease: "easeInOut",
              loop: Infinity,
              repeatDelay: 0,
            }}
          >
            |
          </motion.div>
        </div>
        <ItemsCarousel
          classes={{
            wrapper: "TechnologiesList",
          }}
          requestToChangeActive={setActiveItemIndex}
          activeItemIndex={activeItemIndex}
          numberOfCards={numberOfCards}
          infiniteLoop={true}
        >
          {data && data.length > 0
            ? data.map(
                (
                  item: TechnologiesVO,
                  index: number,
                  arr: TechnologiesVO[]
                ) => {
                  return (
                    <TechnologiesItem
                      key={`TechnologiesItem_${item.index}`}
                      title={item.title}
                    />
                  );
                }
              )
            : null}
        </ItemsCarousel>
      </div>
    </div>
  );
}
