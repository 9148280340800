import React, { PointerEvent, useEffect, useState } from "react";
import "./Trailer.scss";
import { MathUtils } from "three";
import { motion } from "framer-motion";
import VizSensor from "react-visibility-sensor";
import RedVideo from "../Common/RedVideo/RedVideo";

type TrailerProps = {
  currentLanguage: string;
};

export default function Trailer(props: TrailerProps) {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [transition, setTransition] = useState<number[]>([0, 0, 500, 0]);
  const [mouse, setMouse] = useState<number[]>([0, 0]);

  function onVisibleChangeHandler(aVisible: boolean) {
    setIsVisible(aVisible);
  }

  useEffect(() => {
    var theTimeout: any = null;
    if (isVisible && transition[0] !== 1) {
      theTimeout = window.setTimeout(() => {
        setTransition([1, 0, 0, 1]);
      }, 100);
    } else if (!isVisible && transition[0] !== 0) {
      setTransition([0, 0, 500, 0]);
    }
    return () => {
      if (theTimeout !== null) {
        window.clearTimeout(theTimeout);
      }
    };
  }, [isVisible]);

  function onPointerMoveHandler(event: PointerEvent<HTMLDivElement>) {
    const dragX = -MathUtils.clamp(
      event.clientX - window.innerWidth / 2,
      -10,
      10
    );
    const dragY = -MathUtils.clamp(
      event.clientY - window.innerHeight / 2,
      -10,
      10
    );
    setMouse([
      MathUtils.clamp(dragX, -4, 4),
      MathUtils.clamp(dragY * -1, -7, 7)
    ]);
  }

  return (
    <VizSensor partialVisibility onChange={onVisibleChangeHandler}>
      <motion.div
        className="Trailer"
        key="Trailer"
        initial={{
          scale: 1.0
        }}
        onPointerMove={onPointerMoveHandler}
        whileHover={{
          scale: 1.1
        }}
      >
        <div
          className={
            transition[3] === 0
              ? "TrailerContent TrailerContentHide"
              : "TrailerContent"
          }
          style={{
            transform: `perspective(400px) rotateY(${mouse[0]}deg) rotateX(${mouse[1]}deg) translateX(${transition[1]}px) translateY(${transition[2]}px)`,
            opacity: transition[3]
          }}
        >
          <div className="TrailerVideo">
            <RedVideo
              isPlaying={isVisible}
              controls={false}
              src={
                props.currentLanguage === "pt"
                  ? "Trailer_pt.mp4"
                  : "Trailer_en.mp4"
              }
              className="RedVideoShadow"
            />
          </div>
        </div>
      </motion.div>
    </VizSensor>
  );
}
