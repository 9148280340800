import React from "react";
import "./ServicesListItem.scss";
import { motion } from "framer-motion";
import { ServicesVO } from "../../../types/ServicesVO";

type ServicesListItemProps = {
  item: ServicesVO;
  title: string;
  onItemSelecting?: Function;
  onItemSelected?: Function;
  isSelected?: boolean;
};

export default function ServicesListItem(props: ServicesListItemProps) {
  function onAddressKeyDownHandler() {}

  function onItemSelectingHandler() {
    if (props.onItemSelecting) {
      props.onItemSelecting(props.item);
    }
  }

  function onItemSelectedHandler() {
    if (props.onItemSelected) {
      props.onItemSelected(props.item);
    }
  }
  
  return (
    <motion.div
      className={props.isSelected ? "ServicesListItem ServicesListItemSelected" : "ServicesListItem"}
      key="ServicesListItem"
      onClick={onItemSelectedHandler}
      onTouchStart={onItemSelectingHandler}
      onTouchEnd={onItemSelectedHandler}
      onHoverStart={onItemSelectingHandler}
      onHoverEnd={onItemSelectedHandler}
      onKeyDown={onAddressKeyDownHandler}
      onTap={onAddressKeyDownHandler}
      tabIndex={0}
      role="button"
      initial={{
        scale: 1.0
      }}
      whileHover={{
        scale: 1.2
      }}
    >
      <div className="ServicesListItemText">
        {props.title}
      </div>
    </motion.div>
  );
}
