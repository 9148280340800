import React, { CSSProperties } from "react";
import "./About.scss";
import ReactHtmlParser from "react-html-parser";

type AboutProps = {
  t: Function;
  style?: CSSProperties;
};

export default function About(props: AboutProps) {
  return (
    <div className="About" style={props.style}>
      <div className="AboutInfo">
        <div className="AboutInfoContent">
          <div className="AboutTitle">
            {ReactHtmlParser(props.t("about.title"))}
          </div>
          <div className="AboutText">
            {ReactHtmlParser(props.t("about.text"))}
          </div>
        </div>
      </div>
    </div>
  );
}
