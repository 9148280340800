import { BustoToolsParams } from "../../types/BustoToolsVO";

const defaultParamsLogo:BustoToolsParams = {
	version: "1.0.1",
	enableTools: false,
	helpers: {
		enableAxisHelper: false,
		enableLightHelpers: false
	},
	scene: {
		invalidateFrameloop: true,
		targetFPS: 60,
		autoRotateObjects: false,
		objectRotationRate: 0.005
	},
	cameraStart: {
		positionX: 0.04,
		positionY: -0.75,
		positionZ: 3.60,
		lookAtX: 0.3,
		lookAtY: 0.5,
		lookAtZ: 0,
		fov: 46,
		near: 0.1,
		far: 500,
		focus: 3.2,
		filmGauge: 35,
		filmOffset: 0
	},
	cameraEnd: {
		positionX: 0.04,
		positionY: -1.03,
		positionZ: 4.27,
		lookAtX: 0.3,
		lookAtY: 0.5,
		lookAtZ: 0,
		fov: 46,
		near: 0.1,
		far: 500,
		focus: 3.2,
		filmGauge: 35,
		filmOffset: 0
	},
  cameraStartMobile: {
		positionX: 0.04,
		positionY: -0.75,
		positionZ: 3.00,
		lookAtX: 0.3,
		lookAtY: 0.5,
		lookAtZ: 0,
		fov: 46,
		near: 0.1,
		far: 500,
		focus: 3.2,
		filmGauge: 35,
		filmOffset: 0
	},
	cameraEndMobile: {
		positionX: 0.04,
		positionY: -1.04,
		positionZ: 4.26,
		lookAtX: 0.3,
		lookAtY: 0.5,
		lookAtZ: 0,
		fov: 46,
		near: 0.1,
		far: 500,
		focus: 3.2,
		filmGauge: 35,
		filmOffset: 0
	},
	ambientLight: {
		enable: false,
		intensity: 0.09,
		color: "#111111"
	},
	pointlights: [{
		id: "1",
		enable: true,
		enableHelper: true,
		intensity: 6.0,
		color: "#111111",
		positionX: 0.0,
		positionY: 0.5,
		positionZ: 3.5,
		decay: 0,
		distance: 0,
		castShadow: false
	}],
	spotlights: [{
		id: "1",
		enable: true,
		enableHelper: true,
		positionX: 0.0,
		positionY: 15.5,
		positionZ: 0.0,
		lookAtX: 0.0,
		lookAtY: 0.0,
		lookAtZ: 3.0,
		angle: 2.65,
		intensity: 12.0,
		color: "#111111",
		decay: 0,
		penumbra: 0.4,
		distance: 55.5,
		castShadow: true
	}],
	shadows: {
		autoUpdate: true,
		bias: 0.0001,
		normalBias: 0.4,
		mapSizeWidth: 2048,
		mapSizeHeight: 2048,
		focus: 0.1,
		radius: 4.0,
		cameraNear: 5,
		cameraFar: 10
	}
};

export default defaultParamsLogo;