import React from "react";
import "./ServicesMobileImage.scss";
import CrossfadeImage from "react-crossfade-image";
import { ServicesVO } from "../../../types/ServicesVO";
import { imagesPath } from "../../../Constants";

type ServicesMobileImageProps = {
  item: ServicesVO;
  title: string;
};

export default function ServicesMobileImage(props: ServicesMobileImageProps) {
  return (
    <CrossfadeImage
      className="ServicesImage"
      src={imagesPath + props.item.icon}
      alt={props.title}
      duration={300}
      style={{
        width: "100%",
        height: "100%",
        objectFit: "cover"
      }}
    />
  );
}
