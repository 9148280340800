import React, {
  CSSProperties,
  FormEvent,
  useEffect,
  useRef,
  useState
} from "react";
import "./Form.scss";
import ReCAPTCHA from "react-google-recaptcha";
import Routes, { getRouteLink } from "../../types/Routes";
import { allowRecaptcha } from "../../Constants";

const inputLimit = 100;
const textLimit = 1000;

type FormProps = {
  t: Function;
  currentLanguage: string;
  style?: CSSProperties;
};

export default function Form(props: FormProps) {
  const formRef = useRef<HTMLInputElement | null>(null);
  const inputName = useRef<HTMLInputElement | null>(null);
  const inputEmail = useRef<HTMLInputElement | null>(null);
  const inputCompany = useRef<HTMLInputElement | null>(null);
  const inputPhone = useRef<HTMLInputElement | null>(null);
  const inputMessage = useRef<HTMLTextAreaElement | null>(null);
  const [feedbackClass, setFeedbackClass] = useState("FormFeedBack");
  const [feedback, setFeedback] = useState<{ error: boolean; msg: string }>({
    error: false,
    msg: ""
  });
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [company, setCompany] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [isSecure, setIsSecure] = useState(false);

  useEffect(() => {
    var timeoutID: any = null;
    var newFeedbackClass = "FormFeedBack";

    if (formRef != null) {
      if (feedback?.msg === "") {
        newFeedbackClass = "FormFeedBack FormFeedBackHidden";
      } else if (feedback?.error === true) {
        newFeedbackClass = "FormFeedBack FormFeedBackError";
      }
      if (feedbackClass !== newFeedbackClass) {
        setFeedbackClass(newFeedbackClass);
      }
      if (feedback?.msg !== "") {
        timeoutID = setTimeout(() => {
          setFeedback({ error: false, msg: "" });
        }, 5000);
      }
    }
    return () => {
      if (timeoutID != null) {
        clearTimeout(timeoutID);
      }
    };
  }, [feedback]);

  function onNameChange(event: FormEvent) {
    const element = event.currentTarget as HTMLInputElement;
    setName(element.value);
  }

  function onEmailChange(event: FormEvent) {
    const element = event.currentTarget as HTMLInputElement;
    setEmail(element.value);
  }

  function onCompanyChange(event: FormEvent) {
    const element = event.currentTarget as HTMLInputElement;
    setCompany(element.value);
  }

  function onPhoneChange(event: FormEvent) {
    const element = event.currentTarget as HTMLInputElement;
    setPhone(element.value);
  }

  function onMessageChange(event: FormEvent) {
    const element = event.currentTarget as HTMLInputElement;
    setMessage(element.value);
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    sendContact();
  }

  function sendContact() {
    const formErrors: string[] = validate();
    if (formErrors.length === 0) {
      var formBody: string[] = [];
      formBody.push(`name=${name}`);
      formBody.push(`email=${email}`);
      formBody.push(`company=${company}`);
      formBody.push(`phone=${phone}`);
      formBody.push(`message=${message}`);
      fetch(getRouteLink(Routes.EMAIL), {
        method: "POST",
        body: formBody.join("&"),
        headers: {
          Accept: "application/x-www-form-urlencoded",
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.success === true) {
            setFeedback({ error: false, msg: props.t("form.success") });
            resetForm();
          } else {
            setFeedback({ error: true, msg: props.t("form.fail") });
          }
        })
        .catch(() => {
          setFeedback({ error: true, msg: props.t("form.fail") });
        });
    } else {
      setFeedback({ error: true, msg: formErrors[0] });
    }
  }

  function isEmpty(value: string | undefined) {
    return !value || value.length === 0;
  }

  function validate(): string[] {
    var errors: string[] = [];

    if (allowRecaptcha && !isSecure) {
      errors.push(props.t("form.recaptcha"));
      return errors;
    }

    if (isEmpty(name)) {
      errors.push(props.t("form.missing_name"));
    }

    if (isEmpty(email)) {
      errors.push(props.t("form.missing_email"));
    } else {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (email && !pattern.test(email)) {
        errors.push(props.t("form.invalid_email"));
      }
    }

    if (isEmpty(message)) {
      errors.push(props.t("form.missing_message"));
    }

    if (name && name.length > inputLimit) {
      errors.push(props.t("form.input_maxed"));
    }
    if (email && email.length > inputLimit) {
      errors.push(props.t("form.input_maxed"));
    }
    if (company && company.length > inputLimit) {
      errors.push(props.t("form.input_maxed"));
    }
    if (phone && phone.length > inputLimit) {
      errors.push(props.t("form.input_maxed"));
    }
    if (message && message.length > textLimit) {
      errors.push(props.t("form.input_maxed"));
    }

    return errors;
  }

  function resetForm() {
    if (inputName.current) {
      inputName.current.value = "";
    }
    if (inputEmail.current) {
      inputEmail.current.value = "";
    }
    if (inputCompany.current) {
      inputCompany.current.value = "";
    }
    if (inputPhone.current) {
      inputPhone.current.value = "";
    }
    if (inputMessage.current) {
      inputMessage.current.value = "";
    }

    setName("");
    setEmail("");
    setCompany("");
    setPhone("");
    setMessage("");
  }

  function onCaptchaSuccessHandler(value: any) {
    setIsSecure(true);
  }

  function onCaptchaFailHandler() {
    setIsSecure(false);
  }

  return (
    <div className="Form" style={props.style} ref={formRef}>
      <div className="FormTitle">{props.t("form.title")}</div>
      <div className={feedbackClass}>{feedback?.msg}</div>
      <form
        id="FormContact"
        className="FormContent"
        onSubmit={(e) => handleSubmit(e)}
        method="POST"
      >
        <div className="FormRow">
          <div className="FormItem">
            <input
              type="text"
              maxLength={inputLimit}
              className="FormControl"
              placeholder={props.t("form.name")}
              onChange={(e) => onNameChange(e)}
              ref={inputName}
            />
          </div>
          <div className="FormItem">
            <input
              type="text"
              maxLength={inputLimit}
              className="FormControl"
              placeholder={props.t("form.email")}
              onChange={(e) => onEmailChange(e)}
              ref={inputEmail}
            />
          </div>
        </div>
        <div className="FormRow">
          <div className="FormItem">
            <input
              type="text"
              maxLength={inputLimit}
              className="FormControl"
              placeholder={props.t("form.company")}
              onChange={(e) => onCompanyChange(e)}
              ref={inputCompany}
            />
          </div>
          <div className="FormItem">
            <input
              type="text"
              maxLength={inputLimit}
              className="FormControl"
              placeholder={props.t("form.phone")}
              onChange={(e) => onPhoneChange(e)}
              ref={inputPhone}
            />
          </div>
        </div>
        <div className="FormRow">
          <div className="FormItem">
            <textarea
              className="FormControl FormControlTextArea"
              maxLength={textLimit}
              placeholder={props.t("form.message")}
              onChange={(e) => onMessageChange(e)}
              ref={inputMessage}
            />
          </div>
        </div>
        <div className="FormRow">
          <div className="FormItem">
            <button
              type="submit"
              className={`FormControl FormButton ${
                !allowRecaptcha || isSecure ? "" : "FormButtonDisabled"
              }`}
            >
              {props.t("form.send")}
            </button>
          </div>
        </div>
      </form>
      {allowRecaptcha ? (
        <div className="FormReCAPTCHA">
          <ReCAPTCHA
            sitekey="6LcKjpMaAAAAAGOeQjMjO6I6lm_NXAkNGq8LSW5a"
            theme="dark"
            hl={props.currentLanguage}
            onChange={onCaptchaSuccessHandler}
            onExpired={onCaptchaFailHandler}
            onErrored={onCaptchaFailHandler}
          />
        </div>
      ) : null}
    </div>
  );
}
