import React, { CSSProperties } from "react";
import "./Footer.scss";
import { GrLinkedin, GrFacebook, GrYoutube, GrInstagram, GrTwitter } from 'react-icons/gr';
import { imagesPath } from '../../Constants';

type FooterProps = {
  t: Function;
  style?: CSSProperties;
};

export default function Footer(props: FooterProps) {
  return (
    <div className="Footer" style={props.style}>
      <div className="FooterContent">
        <div className="FooterItem FooterLogo">
          <img src={`${imagesPath}logo.svg`} alt="RedLab" />
        </div>
        <div className="FooterItem FooterEmail">
          <a className="FooterLink" href="mailto:INFO@REDLAB.PT">{props.t('contacts.email')}</a>
        </div>
        <div className="FooterItem FooterPhone">
          <a className="FooterLink" href="tel:+351249534880">{props.t('contacts.phone')}</a>
          <br />
          <small>{props.t('contacts.phoneCost')}</small>
        </div>
        <div className="FooterItem FooterSocial">
          <a className="FooterLink FooterSocialItem" href="https://www.facebook.com/red.com.pt/" target="_blank" rel="noreferrer">
            <GrFacebook className="FooterIcon" size="18px"/>
          </a>
          <a className="FooterLink FooterSocialItem" href="https://www.instagram.com/red.com.pt/" target="_blank" rel="noreferrer">
            <GrInstagram className="FooterIcon" size="18px"/>
          </a>
          <a className="FooterLink FooterSocialItem" href="https://www.linkedin.com/company/redgroup-comunicacao-de-marcas/" target="_blank" rel="noreferrer">
            <GrLinkedin className="FooterIcon" size="18px"/>
          </a>
          <a className="FooterLink FooterSocialItem" href="https://www.youtube.com/channel/UCUcibAVtPTZH7IIPJZ6LxTw" target="_blank" rel="noreferrer">
            <GrYoutube className="FooterIcon" size="18px"/>
          </a>
          <a className="FooterLink FooterSocialItem" href="https://twitter.com/RedAgency/" target="_blank" rel="noreferrer">
            <GrTwitter className="FooterIcon" size="18px"/>
          </a>
        </div>
      </div>
    </div>
  );
}
