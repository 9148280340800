import React from 'react';
import './TechnologiesItem.scss';

type TechnologiesItemProps = {
    title:string
}

export default function TechnologiesItem(props:TechnologiesItemProps) {
    return (
        <div className="TechnologiesItem">
            <div className="TechnologiesItemText">
                {props.title}
            </div>
        </div>
    )
}
